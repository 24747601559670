import React from 'react';
import { Counter } from './features/counter/Counter';
import { User } from './features/user/User';

function App() {
  const getJson = async () => {
    await fetch('/api/data.json')
      .then(response => {
        // 检查响应状态
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // 将响应体转换为 JSON
      })
      .then(data => {
        console.log(data); // 在这里处理你的数据
      })
      .catch(error => {
        console.error('There was a problem with fetching the JSON file:', error);
      });
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <p>
        <button onClick={getJson}>获取接口数据</button>
      </p>
      <hr />
      <Counter />
      <hr />
      <User />
    </div >
  );
}

export default App;
