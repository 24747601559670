// 此可选代码用于注册一个 service worker。
// register() 默认不会被调用。

// 这使得应用在生产环境下后续访问时加载更快，并赋予它离线能力。然而，这也意味着开发者（和用户）
// 只有在所有已打开的页面标签被关闭后，再次访问页面时才会看到部署的更新，因为以前缓存的
// 资源会在后台更新。

// 要了解更多关于此模型的好处以及如何选择加入的信息，请阅读 https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] 是 IPv6 的 localhost 地址。
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 被认为是 IPv4 的 localhost。
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // URL 构造函数在所有支持 SW 的浏览器中都可用。
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // 如果 PUBLIC_URL 和页面所在的 origin 不同，我们的 service worker 将无法工作。
      // 这种情况可能发生在使用 CDN 服务资源时；参见 https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // 正在 localhost 上运行。检查是否仍然存在 service worker。
        checkValidServiceWorker(swUrl, config);

        // 在 localhost 上添加一些额外的日志记录，指向 service worker/PWA 文档。
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service ' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // 不是 localhost。只需注册 service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // 此时，已获取更新的预缓存内容，
              // 但旧的 service worker 将继续为所有客户端标签提供旧内容，直到所有客户端标签被关闭。
              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://cra.link/PWA.'
              );

              // 执行回调
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // 此时，所有内容已经被预缓存。
              // 现在是显示“内容已缓存以供离线使用”的完美时机。
              console.log('Content is cached for offline use.');

              // 执行回调
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // 检查是否能找到 service worker。如果找不到，则重新加载页面。
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // 确保 service worker 存在，并且我们确实得到了一个 JS 文件。
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // 找不到 service worker。可能是不同的应用。重新加载页面。
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // 找到 service worker。继续正常流程。
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
