import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

function updateAge(age: number) {
  return new Promise<any>(resolve => setTimeout(() => resolve({ age: age }), 500));
}

export interface UserState {
  value: any;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  value: {
    username: 'user1',
    age: 18
  },
  status: 'idle'
};

export const incrementAsync = createAsyncThunk('counter/updateAge', async (age: number) => {
  const response = await updateAge(age);
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    increment: state => {
      state.value.age += 1;
    },
    decrement: state => {
      state.value.age -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      console.log('incrementByAmount', state);
      // state.value += action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(incrementAsync.pending, state => {
        state.status = 'loading';
        console.log('addCase loading');
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log('addCase fulfilled');
        // state.value += action.payload;
      })
      .addCase(incrementAsync.rejected, state => {
        console.log('addCase rejected');
        // state.status = 'failed';
      });
  }
});

// 导出 actions
export const { increment, decrement, incrementByAmount } = userSlice.actions;

// 导出 选择器
export const selectUser = (state: RootState) => state.user;

// 手工编写thunk，它可能同时包含同步和异步逻辑。
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentUser = selectUser(getState());
    if (currentUser.value.username === 'user1') {
      console.log('incrementIfOdd');
      // dispatch(incrementByAmount(amount));
    }
  };
export default userSlice.reducer;
