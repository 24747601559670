import React, { useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  decrement,
  increment,
  incrementByAmount,
  incrementAsync,
  incrementIfOdd,
  selectUser,
} from './userSlice';


import styles from './User.module.css';

export const User = () => {
  const user = useAppSelector(selectUser);
  console.log('user', user);
  const dispatch = useAppDispatch();
  const [incrementAgeAmount, setIncrementAgeAmount] = useState('2');
  const incrementValue = Number(incrementAgeAmount) || 0;

  return (
    <div>
      <div className={styles.row}>
        <button
          className={styles.button}
          aria-label="Decrement value"
          onClick={() => {
            dispatch(decrement())
          }}
        >
          年龄-1
        </button>
        <span className={styles.value}>{user.value.age}</span>
        <button
          className={styles.button}
          aria-label="Increment value"
          onClick={() => {
            dispatch(increment())
          }}
        >
          年龄+1
        </button>
      </div>
      <div className={styles.row}>
        <input
          className={styles.textbox}
          aria-label="设置增量"
          value={incrementAgeAmount}
          onChange={(e) => {
            setIncrementAgeAmount(e.target.value)
          }}
        />
        <button
          className={styles.button}
          onClick={() => dispatch(incrementByAmount(incrementValue))}
        >
          年龄+增量
        </button>
        <button
          className={styles.asyncButton}
          onClick={() => dispatch(incrementAsync(incrementValue))}
        >
          异步:年龄+增量
        </button>
        <button
          className={styles.button}
          onClick={() => dispatch(incrementIfOdd(incrementValue))}
        >
          Add If Odd
        </button>
      </div>
    </div>
  );
}